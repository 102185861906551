import styled from 'styled-components/macro'
import { Button } from 'assets/elements'

export const StyledButton = styled(Button)<{
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  text-transform: uppercase;
  background: var(--secondary);
  color: ${p => p.fontOnSecondary};
  font-weight: bold;
  font-size: 13px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-height: 35px;
  :hover {
    color: var(--grey050);
    background: var(--primary);
  }
  svg {
    min-width: 16px;
    min-height: 16px;
  }
`
